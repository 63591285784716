.NavigationItems {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  padding: 10px 0px;
  flex-direction: column;
  width: 100%;
  flex: 1;
}

.tooltip {
  position: relative;
  display: inline-block;
  margin-left: 50px;
  margin-top: 2px;
}

.logout {
  margin-top: 35px;
  color: #fff;
}

.tooltip:hover,
.logout {
  cursor: pointer;
}

.tooltip2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.tooltip2:hover {
  cursor: pointer;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #000000;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 150%;
  left: 50%;
  margin-left: -60px;
  font-size: 0.7rem;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #000000 transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .tooltip {
    display: none;
  }
  .tooltip2 {
    display: none;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .tooltip {
    display: none;
  }
  .tooltip2 {
    display: none;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .tooltip {
    display: none;
  }
  .tooltip2 {
    display: none;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .NavigationItems {
    flex-direction: row;
    justify-content: flex-end;
  }
  .logout {
    display: none;
  }
  .tooltip {
    display: inline-block;
  }
  .tooltip2 {
    display: flex;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .NavigationItems {
    flex-direction: row;
    justify-content: flex-end;
  }
}

.myItem {
  box-sizing: border-box;
  display: block;
  width: 100%;
  font-size: 1em;
  color: #ffffff;
  text-decoration: none;
  width: 100%;
  display: block;
  font-weight: bold;
}

@media (min-width: 992px) {
  .myItem {
    margin-left: 20px;
    display: flex;
    height: 100%;
    width: auto;
    align-items: center;
  }

  .myItem a {
    height: 100%;
    /* padding: 16px 10px; */
    border-bottom: 4px solid transparent;
  }

  .myItem {
    color: #ffffff;
    display: inline-block;
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .NavigationItems {
    flex-direction: row;
    justify-content: flex-end;
  }
}

.NavigationItem {
  box-sizing: border-box;
  display: block;
  width: 100%;
  font-size: 1.2em;
  cursor: pointer;
  color: #ffffff;
}

.NavigationItem:hover,
.NavigationItem:active {
  color: #ffffff;
}

@media (min-width: 992px) {
  .NavigationItem {
    margin-left: 20px;
    display: flex;
    height: 100%;
    width: auto;
    align-items: center;
  }

  .NavigationItem a {
    height: 100%;
    /* padding: 16px 10px; */
    border-bottom: 4px solid transparent;
  }

  .NavigationItem:hover,
  .NavigationItem:active {
    /* border-bottom: 3px solid #ffffff; */
    color: #bbbbbb;
  }

  .NavigationItem:hover:after {
    transform: scaleX(1);
    transform-origin: 0% 50%;
  }
  .NavigationItem:after {
    display: block;
    content: "";
    /* width: 50%; */
    margin-top: 5px;
    border-bottom: solid 3px #ffffff;
    transform: scaleX(0);
    transition: transform 200ms ease-in-out;
    /* transform-origin: 0% 50%; */
    transform-origin: 100% 50%;
  }
  .NavigationItem {
    color: #ffffff;
    display: inline-block;
  }
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  /* .action {
    background-color: #fccc0a;
    font-weight: bold;
    padding: 0px 10px;
  } */
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}

.link {
  box-sizing: border-box;
  display: block;
  width: 100%;
  font-size: 1em;
}

.link a {
  color: black;
  text-decoration: none;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  padding-top: 15px;
  padding-bottom: 15px;
}

.link a:hover {
  background-color: #9d01e110;
}

.unactive {
  border-left: 5px solid transparent;
}

.activeLink {
  border-left: 5px solid #9d01e1;
  background-color: #9d01e110;
}

.margin {
  margin-left: 25px;
  display: inline-flex;
  align-items: center;
}

.titleHidden {
  -webkit-transition: all 200ms ease-out;
  -moz-transition: all 200ms ease-out;
  -o-transition: all 200ms ease-out;
  transition: all 200ms ease-out;
  opacity: 0;
  display: inline-flex;
  margin-left: 10px;
  max-lines: 1;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.titleShow {
  transition: all 200ms;
  margin-left: 10px;
  max-lines: 1;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}

.NavigationItem {
  box-sizing: border-box;
  display: block;
  width: 100%;
  font-size: 1.2em;
}

.NavigationItem a {
  color: #ffffff;
  text-decoration: none;
  width: 100%;
  box-sizing: border-box;
  display: block;
}

.NavigationItem a:hover,
.NavigationItem a:active,
.NavigationItem a.active {
  color: #ffffff;
}

@media (min-width: 992px) {
  .NavigationItem {
    margin-left: 20px;
    display: flex;
    height: 100%;
    width: auto;
    align-items: center;
  }

  .NavigationItem a {
    height: 100%;
    /* padding: 16px 10px; */
    border-bottom: 4px solid transparent;
  }

  .NavigationItem a:hover,
  .NavigationItem a:active,
  .NavigationItem a.active {
    /* border-bottom: 3px solid #ffffff; */
    color: #bbbbbb;
  }

  .NavigationItem:hover:after {
    transform: scaleX(1);
    transform-origin: 0% 50%;
  }
  .NavigationItem:after {
    display: block;
    content: "";
    /* width: 50%; */
    border-bottom: solid 3px #ffffff;
    transform: scaleX(0);
    transition: transform 200ms ease-in-out;
    /* transform-origin: 0% 50%; */
    transform-origin: 100% 50%;
  }
  .NavigationItem {
    color: #ffffff;
    display: inline-block;
  }
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  /* .action {
    background-color: #fccc0a;
    font-weight: bold;
    padding: 0px 10px;
  } */
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}

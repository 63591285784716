.hole {
  display: flex;
  flex: 1;
  background-image: linear-gradient(-45deg, #e0d5ff, #f2fbff);
  height: 100vh;
  min-width: 100vw;
  justify-content: center;
  align-items: center;
  overflow: auto;
  position: fixed;
  z-index: 10;
  flex-direction: column;
}

.banners {
  margin-top: 9vh;
  display: flex;
  flex-direction: row;
  margin-bottom: 8vh;
}

.banner {
  cursor: pointer;
}

.card {
  min-height: 450px;
  max-width: 414px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  margin-right: 10px;
  margin-left: 10px;
}

.header {
  height: 70px;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  background-color: #9570fc;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 42px;
  padding-right: 42px;
}

.body {
  flex: 1;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  background-color: white;
  padding-top: 42px;
  padding-bottom: 25px;
  padding-left: 35px;
  padding-right: 35px;
  /* padding: 42px; */
  display: flex;
  flex-direction: column;
}

.test {
  color: green;
}

.lineHeight {
  line-height: 28px;
}

.register {
  color: #8b37ff;
  text-decoration: none;
  font-weight: bold;
}

.question {
  font-weight: bold;
  align-self: center;
  text-align: center;
  margin-top: 10px;
}

@media only screen and (max-width: 440px) {
  /* .card {
    width: calc(100vw - 20px);
  } */
}

@media only screen and (min-width: 440px) {
  .card {
    min-width: 400px;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .card {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .header {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }

  .body {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}

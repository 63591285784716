/* .codeInput {
  border-radius: 10px;
}

.codeInput:first-child {
  color: red;
}
.codeInput:last-child {
  color: red;
} */
.react-code-input {
  display: flex;
  /* background-color: aqua; */
  flex-direction: row;
  justify-content: space-between;
}

.home {
  display: flex;
  flex-direction: row;
}

.welcome {
  flex: 2;
  display: flex;
}

.waitlist {
  display: flex;
  width: 640px;
  height: 150px;
  border: 1px solid #dadada;
  border-radius: 20px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .border {
    border-right: none;
    border-bottom: 1px solid #9570fc;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .border {
    border-right: none;
    border-bottom: 1px solid #9570fc;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .border {
    border-right: 1px solid #9570fc;
    border-bottom: none;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .border {
    border-right: 1px solid #9570fc;
    border-bottom: none;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .border {
    border-right: 1px solid #9570fc;
    border-bottom: none;
  }
}

.H3BOLD {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 1.5625rem;
}
.H3SEMIBOLD {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 1.5625rem;
}
.H3REGULAR {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 1.5625rem;
}

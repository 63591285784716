.hole {
  display: flex;
  flex: 1;
  background-image: linear-gradient(-45deg, #e0d5ff, #f2fbff);
  min-height: 100vh;
  /* min-width: 100vw; */
  justify-content: center;
  align-items: center;
  /* overflow: auto; */
  /* position: fixed; */
  z-index: 10;
}
.background {
  background-color: #f0ebff;
  min-height: calc(100vh - 71px);
  padding-bottom: 100px;
}

.errors {
  color: #bf1650;
  font-size: 12px;
  user-select: none;
  margin-bottom: 10px;
}

.errors::before {
  display: inline;
  content: "⚠ ";
}

.errorsHidden {
  color: transparent;
  font-size: 12px;
  user-select: none;
  margin-bottom: 10px;
}

.input {
  height: 100%;
  border-radius: 5px;
  text-align: left;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid #bbbbbb;
  width: 100%;
  font-family: "Montserrat";
  height: 50px;
  font-size: 1rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.input:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(13, 110, 253, 0.25);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.nameSeparatorRight {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-right: 10px;
}

.nameSeparatorLeft {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.label {
  line-height: 2;
  text-align: left;
  display: block;
  /* font-size: 14px; */
  font-weight: 200;
}

.names {
  flex-direction: row;
  display: flex;
  /* background-color: #bf1650; */
}

.button {
  height: 53px;
  background-color: #8b37ff;
  display: "flex";
  justify-content: "center";
  align-items: "center";
  border-radius: 10px;
  width: 100%;
  border: none;
  color: white;
  margin-top: 0px;
}

input[type="button"]:active,
input[type="submit"]:active {
  transition: 0.3s all;
  border: 1px solid transparent;
  opacity: 0.8;
}

input[type="submit"] {
  font-weight: bold;
}

input:disabled {
  opacity: 0.4;
}

input[type="button"]:hover {
  transition: 0.3s all;
}

input[type="button"],
input[type="submit"] {
  -webkit-appearance: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.test:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(13, 110, 253, 0.25);
}

.wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 5px;
  width: 100%;
}
.wrapper .option {
  height: 100%;
  /* width: 100%; */
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 0 10px;
  border-radius: 5px;
  cursor: pointer;
  padding: 0 10px;
  border: 2px solid #bbbbbb;
  transition: all 0.3s ease;
}
.wrapper .option .dot {
  height: 15px;
  width: 15px;
  background: #d9d9d9;
  border-radius: 50%;
  position: relative;
}

.dot {
  margin-right: 10px;
}
.wrapper .option .dot::before {
  position: absolute;
  content: "";
  top: 5px;
  left: 5px;
  width: 5px;
  height: 5px;
  background: #9752f8;
  border-radius: 50%;
  opacity: 0;
  transform: scale(1.5);
  transition: all 0.3s ease;
}
input[type="radio"] {
  display: none;
}
.op1:checked:checked ~ .option1,
.op2:checked:checked ~ .option2 {
  border-color: #9752f8;
  background: #9752f8;
}
.op1:checked:checked ~ .option1 .dot,
.op2:checked:checked ~ .option2 .dot {
  background: #fff;
}
.op1:checked:checked ~ .option1 .dot::before,
.op2:checked:checked ~ .option2 .dot::before {
  opacity: 1;
  transform: scale(1);
}
.wrapper .option span {
  font-size: 20px;
  color: #808080;
}
.op1:checked:checked ~ .option1 span,
.op2:checked:checked ~ .option2 span {
  color: #fff;
}

@media only screen and (min-width: 992px) {
  .background {
    background-image: url("../../../Assets/Images/modalBackground.png");
    background-position: center;
    min-height: calc(100vh - 71px);
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    /* padding: 0; */
    /* scroll-padding: 0; */
  }
}

.register {
  color: #8b37ff;
  text-decoration: none;
  font-weight: bold;
}

.question {
  font-weight: bold;
  align-self: center;
  text-align: center;
  margin-top: 10px;
}

.required {
  color: #bf1650;
  margin-left: 0.5rem !important;
}

/* .hole {
  display: flex;
  flex: 1;
  background-image: linear-gradient(-45deg, #e0d5ff, #f2fbff);
  height: calc(100vh - 71px);
  min-width: 100vw;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: fixed;
  z-index: 10;
} */

.card {
  padding-right: 30px;
  padding-left: 30px;
  margin-top: 25px;
  background-color: #ffffff;
  margin-bottom: 100px;
  padding-bottom: 40px;
  border-radius: 10px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}

input[type="file"] {
  display: none;
}
.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

.file-preview {
  margin: 0 10px;
}

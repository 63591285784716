.H6BOLD {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 1.125rem;
}
.H6SEMIBOLD {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 1.125rem;
}
.H6REGULAR {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 1.125rem;
}

.home {
  display: flex;
  flex-direction: row;
}

.homeWrapper {
  height: calc(100vh - 71px);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  /* margin-bottom: 10px; */
}

.welcome {
  flex: 2;
  display: flex;
}

.waitlist {
  display: flex;
  width: 640px;
  height: 150px;
  border: 1px solid #dadada;
  border-radius: 20px;
}

.clear {
  margin-left: 15px;
  border: 1px solid lightgrey;
}

.tableStyle {
  border: 1px solid lightgrey;
  border-radius: 10px;
  padding: 20px 20px;
  margin-top: 10px;
}

.search {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.upArrow {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;

  border-bottom: 5px solid black;
}

.downArrow {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;

  border-top: 5px solid black;
}

table {
  table-layout: auto !important;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.buttonAdd {
  height: 30px;
}

.accept {
  border: 2px solid #5ad951;
  padding: 2px 15px;
  border-radius: 5px;
  cursor: pointer;
}

.actions {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.assignPass {
  border: 2px solid #9570fc;
  height: 35px;
  width: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  color: #9570fc;
}

.dialog {
  display: flex;
  align-self: center;
  align-content: center;
  justify-content: center;
}

.content {
  max-width: 610px;
  align-self: center;
}

.cancel {
  border: 2px solid #ec8686;
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .border {
    border-right: none;
    border-bottom: 1px solid #9570fc;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .border {
    border-right: none;
    border-bottom: 1px solid #9570fc;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .border {
    border-right: 1px solid #9570fc;
    border-bottom: none;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .border {
    border-right: 1px solid #9570fc;
    border-bottom: none;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .border {
    border-right: 1px solid #9570fc;
    border-bottom: none;
  }
}

.promoModal {
  max-width: 600px;
}

.input {
  height: 100%;
  border-radius: 5px;
  text-align: left;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid #bbbbbb;
  width: 300px;
  font-family: "Montserrat";
  height: 50px;
  font-size: 1rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-top: 25px;
}

.input:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(13, 110, 253, 0.25);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.button {
  height: 53px;
  background-color: #8b37ff;
  display: "flex";
  justify-content: "center";
  align-items: "center";
  border-radius: 10px;
  width: 300px;
  border: none;
  color: white;
  margin-top: 35px;
}

.body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
}

.toast {
  position: absolute;
  top: 100px;
  right: 35px;
  z-index: 2000;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}

.PCAPTIONBOLD {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 0.75rem;
}
.PCAPTIONSEMIBOLD {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 0.75rem;
}
.PCAPTIONREGULAR {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
}

.H4BOLD {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 1.375rem;
}
.H4SEMIBOLD {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 1.375rem;
}
.H4REGULAR {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 1.375rem;
}

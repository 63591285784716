/* The following block can be included in a custom.scss */

/* make the customizations */
$theme-colors: (
  "primary": #9570fc,
  "secondary": #8b37ff,
  "info": #fccc0a,
  "danger": #f72d67,
  "success": #8dc641,
  "light": #ffffff,
  "dark": #000000,
  "warning": #f72d67,
);

/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";

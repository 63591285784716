.H5BOLD {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 1.25rem;
}
.H5SEMIBOLD {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 1.25rem;
}
.H5REGULAR {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 1.25rem;
}

.sideBar {
  background-color: #e8e0fd;
  min-height: calc(100vh - 71px);
  display: none;
  flex-direction: column;
  width: 270px;
  box-sizing: border-box;
  transition: all 400ms;
  /* position: fixed; */
}

.sideBar2 {
  background-color: #e8e0fd;
  min-height: calc(100vh - 71px);
  display: none;
  flex-direction: column;
  width: 90px;
  box-sizing: border-box;
  transition: all 400ms;
  /* position: fixed; */
}

.header {
  padding: 25px;
}

.links {
  flex: 1;
  width: 100%;
  flex-direction: column;
  /* align-items: stretch; */
}

.iconStyle {
  transition: all 400ms;
  transform: rotate(-180deg);
  width: 100%;
}

.iconStyle2 {
  transition: all 400ms;
  transform: rotate(0deg);
  width: 100%;
}

.icon {
  padding: 25px 10px;
  display: flex;
  transition: all 400ms;
}

.icon:hover {
  cursor: pointer;
}

.icon2 {
  align-self: center;
  padding: 25px 10px;
  width: 100%;
  display: flex;
  transition: all 400ms;
}

.icon2:hover {
  cursor: pointer;
}

.footer {
  font-size: 0.8rem;
  align-self: center;
  text-align: center;
  -webkit-transition: all 200ms ease-out;
  -moz-transition: all 200ms ease-out;
  -o-transition: all 200ms ease-out;
  transition: all 200ms ease-out;
}

.collapsedFooter {
  -webkit-transition: all 200ms ease-out;
  -moz-transition: all 200ms ease-out;
  -o-transition: all 200ms ease-out;
  transition: all 200ms ease-out;
  max-lines: 1;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

@media only screen and (min-width: 977px) {
  .sideBar {
    display: flex;
  }
  .sideBar2 {
    display: flex;
  }
}

/* Extra small devices (phones, 600px and down) */
/* @media only screen and (max-width: 600px) { */
/* } */

/* Small devices (portrait tablets and large phones, 600px and up) */
/* @media only screen and (min-width: 600px) { */
/* } */

/* Medium devices (landscape tablets, 768px and up) */
/* @media only screen and (min-width: 768px) { */
/* } */

/* Large devices (laptops/desktops, 992px and up) */
/* @media only screen and (min-width: 992px) { */
/* } */

/* Extra large devices (large laptops and desktops, 1200px and up) */
/* @media only screen and (min-width: 1200px) { */
/* } */

.H1BOLD {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 2rem;
}
.H1SEMIBOLD {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 2rem;
}
.H1REGULAR {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 2rem;
}

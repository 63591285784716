.assignPass {
  border: 2px solid #9570fc;
  height: 35px;
  width: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  color: #9570fc;
}

.plans {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.plans2 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.dialog {
  display: flex;
  align-self: center;
  align-content: center;
  justify-content: center;
}

.plan {
  width: 260px;
  height: 43px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  align-items: center;
  border: 2px solid #979797;
  border-radius: 10px;
  margin-top: 8px;
  margin-bottom: 8px;
  cursor: pointer;
}

.content {
  max-width: 610px;
  align-self: center;
}

.body {
  padding: 30px;
  /* overflow-y: scroll; */
}

.outer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 2px solid #979797;
  margin-right: 10px;
}

.inner {
  height: 12px;
  width: 12px;
  background-color: #9570fc;
  border-radius: 50%;
}

.select {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: black;
}

.cost {
  font-weight: bold;
  color: #9570fc;
}

.activePlans {
  min-height: 100px;
  max-height: 300px;
  overflow-y: scroll;
  width: 100%;
}

.loader {
  min-height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.plan2 {
  width: 100%;
  height: 43px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  align-items: center;
  border: 2px solid #979797;
  border-radius: 10px;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-right: 10px;
  margin-left: 10px;
}

.delete {
  cursor: pointer;
}

.buttons {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 579px) {
  .plans {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .plans2 {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .plans {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .plans2 {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}

.codeInput {
  border-radius: 10px;
  border: 1px solid #bbbbbb;
  margin-left: 10px;
  margin-right: 10px;
}

.codeInput:focus {
  border-radius: 10px;
  border: 1px solid black;
  margin-left: 10px;
  margin-right: 10px;
}

.codeInput:first-child {
  margin-left: 0px;
}
.codeInput:last-child {
  margin-right: 0px;
}

.codeFocus {
  border-radius: 10px;
}

.wrapper {
  border: 1px solid green;
}

.test {
  border-left: none;
  background-color: "green";
  border-width: 0px;
}

.component {
  border: 0px solid transparent;
  font-family: "Montserrat", sans-serif;
  /* background-color: bisque; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.component input:focus {
  border: 1px solid rgba(13, 110, 253, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(13, 110, 253, 0.25);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}

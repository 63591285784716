.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 71px);
}

.settings {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 45vw;
}

.errors {
  color: #bf1650;
  font-size: 12px;
  user-select: none;
  margin-bottom: 10px;
}

.errors::before {
  display: inline;
  content: "⚠ ";
}

.errorsHidden {
  color: transparent;
  font-size: 12px;
  user-select: none;
  margin-bottom: 10px;
}

.input {
  height: 100%;
  border-radius: 5px;
  text-align: left;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid #bbbbbb;
  width: 100%;
  font-family: "Montserrat";
  height: 50px;
  font-size: 1rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.input:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(13, 110, 253, 0.25);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.button {
  height: 53px;
  background-color: #8b37ff;
  display: "flex";
  justify-content: "center";
  align-items: "center";
  border-radius: 10px;
  width: 100%;
  border: none;
  color: white;
  margin-top: 0px;
}

input[type="button"]:active,
input[type="submit"]:active {
  transition: 0.3s all;
  border: 1px solid transparent;
  opacity: 0.8;
}

input[type="submit"] {
  font-weight: bold;
}

input:disabled {
  opacity: 0.4;
}

input[type="button"]:hover {
  transition: 0.3s all;
}

input[type="button"],
input[type="submit"] {
  -webkit-appearance: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .settings {
    max-width: 75vw;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .settings {
    max-width: 75vw;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .settings {
    max-width: 65vw;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .settings {
    max-width: 50vw;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .settings {
    max-width: 35vw;
  }
}

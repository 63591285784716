.cart {
  /* max-height: 65vh; */
  width: 100%;
  /* overflow-y: scroll; */
  /* background-color: antiquewhite; */
}

.prueba > tbody > tr:nth-of-type(odd) {
  background-color: #9570fc30 !important;
}

.prueba > tbody > tr {
  vertical-align: middle;
}

.myRow {
  background-color: white;
  margin-right: 12px;
  margin-left: 12px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
  padding-right: 10px;
  margin-bottom: 15px;
  box-shadow: 0px 0px 15px 0 rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.cell {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cell2 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.cell3 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.myRow:first-child {
  margin-top: 20px;
}

.quantity {
  width: 40px;
  /* height: 40px; */
  /* border-radius: 50%; */
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  margin-right: 5px;
  margin-left: 5px;
  border: none;
  text-align: center;
  color: black;
  font-weight: bold;
}

.iconNew {
  width: 60px;
  height: 30px;
  border-radius: 5px;
  background-color: #9570fc20;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  border: none;
  text-align: center;
  color: black;
  font-weight: bold;
  color: #9570fc;
  cursor: pointer;
}

.separator {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  margin-top: 15px;
}

.line {
  height: 1px;
  display: flex;
  flex: 1;
  background-color: #dadada;
}

.separatorText {
  margin-right: 25px;
  margin-left: 25px;
}

.icon {
  flex-direction: row;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.description {
  margin-top: 15px;
  margin-bottom: 10px;
}

.cart2 {
  display: flex;
  flex-direction: column;
  background-color: #9570fc20;
  border-radius: 10px 10px 10px 10px;
  padding: 20px;
  margin-bottom: 35px;
}

.header {
  border-bottom: 1px solid rgb(230, 230, 230);
  padding-top: 15px;
  padding-left: 15px;
  padding-bottom: 10px;
}

.content {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-left: 15px;
  padding-right: 15px;
  justify-content: flex-start;
  margin-top: 30px;
}

.summaryRow {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  flex: 1;
}

.discountCorrect {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 10px;
  background-color: #8dc64130;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #70ae1d;
}

.discountIncorrect {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 10px;
  background-color: #c64a4130;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #bd3c3c;
}

.hidden {
  visibility: hidden;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 10px;
  background-color: #8dc64130;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #bd3c3c;
}

.deleteDiscount {
  align-self: center;
  font-size: 0.8em;
  cursor: pointer;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hiddenDiscount {
  align-self: center;
  font-size: 0.8em;
  cursor: pointer;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
}

.headerTable > th {
  font-weight: 300;
}

.summaryTotal {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-weight: bolder;
  font-size: 1.2rem;
}

.fakebutton {
  width: 197.76px;
  border-radius: 10px;
  height: 48px;
  line-height: 48px;
  font-family: "Nexa";
  font-size: 19px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  padding: 1px 6px;
  transition: 0.5s;
  box-sizing: border-box;
  color: rgb(243, 243, 243);
  background-color: #bbbbbb;
  border: 2px solid #bbbbbb;
  --button-border-color: #bbbbbb;
  text-align: center;
}

.promo {
  font-weight: 600;
  display: flex;
  align-items: center;
}

.discount {
  font-weight: bold;
}

.footer {
  background-color: whitesmoke;
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: 1px solid rgb(227, 227, 227);
  border-radius: 0px 0px 10px 10px;
  font-weight: bold;
}

.modal {
  background-image: url("../../../Assets/Images/modalBackground.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.paymentWrapper {
  display: flex;
  margin-bottom: 0px;
  flex-direction: column;
}

.paypalWrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  padding-bottom: 15px;
}

.ubiiWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.buttonPayment {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.input {
  height: 100%;
  border-radius: 5px;
  text-align: left;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid transparent;
  width: 100%;
  font-family: "Montserrat";
  height: 50px;
  font-size: 1rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-top: 20px;
  margin-bottom: 20px;
}

.input:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(13, 110, 253, 0.25);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

input[type="button"]:active,
input[type="submit"]:active {
  transition: 0.3s all;
  border: 1px solid transparent;
  opacity: 0.8;
}
.button {
  height: 40px;
  background-color: #8b37ff;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  color: white;
  border-radius: 5px;
  padding-right: 20px;
  padding-left: 20px;
  font-weight: bold;
}

.discountInput {
  height: 40px;
  text-align: left;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid transparent;
  width: 100%;
  font-family: "Montserrat";
  font-size: 1rem;
  background-color: transparent;
  margin-right: 25px;
}

.discountInput:focus {
  outline: 0;
  border-bottom: none;
  border-top: none;
  border-left: none;
  border-right: none;
}

.discountInput:active {
  outline: 0;
  border-bottom: none;
  border-top: none;
  border-left: none;
  border-right: none;
}

.form {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 5px;
  padding-bottom: 5px;
  border-bottom: 2px solid #8b37ff;
}

.formFocused {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 5px;
  padding-bottom: 5px;
  border-bottom: 2px solid #8b37ff;
}

input:disabled {
  opacity: 0.4;
}

input[type="button"]:hover {
  transition: 0.3s all;
}

input[type="button"],
input[type="submit"] {
  -webkit-appearance: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.footerTable {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.tableRow {
  height: 60px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  /* .cell3 {
    justify-content: center;
  }
  .cell2 {
    justify-content: center;
  } */
}

@media only screen and (max-width: 991px) {
  .cart2 {
    margin-top: 35px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .cell3 {
    justify-content: flex-start;
  }
  .cell2 {
    justify-content: center;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .cell3 {
    justify-content: flex-start;
  }
  .cell2 {
    justify-content: center;
  }
}

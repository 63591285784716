.package {
  width: 100%;
  position: relative;
  margin-bottom: 10px;
  user-select: none;
  -webkit-user-drag: none;
  height: 63px;
  border-radius: 10px;
  margin-bottom: 15px;
}

.package:hover {
  cursor: pointer;
}

.info {
  top: 0px;
  left: 0px;
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
}
.infoWrapper {
  display: flex;
  flex: 1;
  margin: 1.1vw;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.detail {
  flex-direction: row;
  display: flex;
  align-items: center;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}

.radioButton {
  padding: 15px 30px;
  margin-bottom: 10px;
  background-color: white;
  border-radius: 10px;
}

.radioButton:hover {
  cursor: pointer;
}

.formCheck > input:checked {
  background-color: #9570fc;
  border-color: #9570fc;
}

.formCheck2 > input:checked {
  background-color: #9570fc;
  border-color: #9570fc;
}

.selected {
  border: 3px solid #9570fc;
}

.unselected {
  border: 3px solid #ffffff00;
}

.label {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0px;
  margin-top: 10px;
}

.accordionCard {
  background-color: white;
  height: 62px;
  border-radius: 10px;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  user-select: none;
}

.title {
  height: 62px;
  width: 80%;
  padding-left: 25px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.icon {
  height: 62px;
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.selectWrapper {
  margin-top: 20px;
  margin-bottom: 20px;
}

.accordionCard:hover {
  cursor: pointer;
}

.tabs {
  flex-direction: row;
  display: flex;
  user-select: none;
  margin-bottom: 20px;
}

.tabs:hover {
  cursor: pointer;
}

.tab {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-self: center;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.packageCard {
  flex: 1;
  width: 100%;
}

.packages {
  display: flex;
  flex-direction: column;
}

.trips {
  flex-direction: column;
  display: flex;
  flex-wrap: wrap;
}

.modal {
  background-image: url("../../../Assets/Images/modalBackground.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.fakebutton {
  width: 197.76px;
  border-radius: 10px;
  height: 48px;
  line-height: 48px;
  font-family: "Nexa";
  font-size: 19px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  padding: 1px 6px;
  transition: 0.5s;
  box-sizing: border-box;
  color: rgb(243, 243, 243);
  background-color: #bbbbbb;
  border: 2px solid #bbbbbb;
  --button-border-color: #bbbbbb;
  text-align: center;
}

.separator {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
  margin-top: 25px;
}

.continueBack {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  align-self: center;
  margin-bottom: 50px;
}

.buttonWrapper {
  display: flex;
}

.line {
  height: 1px;
  display: flex;
  flex: 1;
  background-color: #dadada;
}

.separatorText {
  margin-right: 25px;
  margin-left: 25px;
}

.ubiiWrapper {
  background-color: #f6f8fc;
  padding: 30px 40px 25px 40px;
  border: 2px #9a00e0 dashed;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.st0 {
  -webkit-transition: all 0s ease 0s !important;
  -moz-transition: all 0s ease 0s !important;
  -o-transition: all 0s ease 0s !important;
  transition: all 0s ease 0s !important;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .form {
    margin-top: 25px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .form {
    margin-top: 25px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .form {
    margin-top: 0px;
  }
}

@media only screen and (max-width: 992px) {
  .paymentsSide {
    margin-top: 20px;
    margin-bottom: 50px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}

.PBODYBOLD {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 0.875rem;
}
.PBODYSEMIBOLD {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
}
.PBODYREGULAR {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
}

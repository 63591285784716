.TITLEBOLD {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 2.625rem;
}
.TITLESEMIBOLD {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 2.625rem;
}
.HTITLEEGULAR {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 2.625rem;
}

.nav {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  flex-direction: row;
  align-items: stretch;
}

.background {
  min-height: 100vh;
  /* overflow: hidden; */
}

.overflow {
  overflow-y: auto;
  width: 100%;
}

.noOverflow {
  width: 100%;
}

.withSideBar {
  display: flex;
  flex-direction: row;
}

.navbar {
  padding-left: 25px;
  padding-right: 25px;
}

.backgroundColumns {
  background-color: #f0ebff;
}

.circleBackground {
  background-image: url("../../Assets/Images/modalBackground.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  align-self: center;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .nav {
    justify-content: flex-start;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .nav {
    justify-content: flex-start;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .nav {
    justify-content: flex-start;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .nav {
    justify-content: flex-end;
    flex-direction: column;
  }
  .navbar {
    padding-left: 25px;
    padding-right: 60px;
  }
  .backgroundColumns {
    background-image: url("../../Assets/Images/fondo.jpg");
    background-position: center;
    min-height: calc(100vh - 71px);
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0;
    scroll-padding: 0;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .nav {
    justify-content: flex-end;
    flex-direction: column;
  }
}

.promoModal {
  max-width: 600px;
}

.input {
  height: 100%;
  border-radius: 5px;
  text-align: left;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid #bbbbbb;
  width: 300px;
  font-family: "Montserrat";
  height: 50px;
  font-size: 1rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-top: 25px;
}

.input:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(13, 110, 253, 0.25);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.button {
  height: 53px;
  background-color: #8b37ff;
  display: "flex";
  justify-content: "center";
  align-items: "center";
  border-radius: 10px;
  width: 300px;
  border: none;
  color: white;
  margin-top: 35px;
}

.body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
}

.toast {
  position: absolute;
  top: 100px;
  right: 35px;
  z-index: 2000;
}
